<template>
  <v-app class="user-events">
    <div class="top-sticky">
      <div class="event-form">
        <validation-observer ref="form" v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(submitForm)">
            <validation-provider
              rules="required|uuid"
              name="User ID"
              v-slot="{ errors }"
            >
              <div
                class="form-row"
                :class="{ 'error': errors.length > 0 }"
              >
                <label for="txtUserID">User ID
                  <fa icon="asterisk" />
                </label>
                <input
                  id="txtUserID"
                  type="text"
                  :disabled="isBusy"
                  placeholder="Enter User ID"
                  v-model="userID"
                >
                <p
                  v-if="errors.length > 0"
                  class="error"
                >
                  {{ errors[0] }}
                </p>
                <p
                  v-if="!isUserIDValid && !errors.length"
                  class="error"
                >Invalid User ID</p>
              </div>
            </validation-provider>
            <validation-provider
              rules="required"
              name="Event Date"
              v-slot="{ errors }"
            >
              <div
                class="form-row"
                :class="{ 'error': errors.length > 0 }"
              >
                <label for="txtEventDate">Event Date
                  <fa icon="asterisk" />
                </label>
                <datepicker
                  id="txtEventDate"
                  format="YYYY-MM-DD"
                  v-model="eventDate"
                  :disabled-date="datepickerDisabledDate"
                  placeholder="Select Event date"
                />
                <p
                  v-if="errors.length > 0"
                  class="error"
                >
                  {{ errors[0] }}
                </p>
                <p
                  v-if="!isDateValid && !errors.length"
                  class="error"
                >Invalid Date</p>
              </div>
            </validation-provider>
            <button
              class="es-btn primary small"
              :disabled="invalid || isBusy"
            >Go</button>
          </form>
        </validation-observer>
        <div>
          <p v-if="userDeleted" class="text-danger">User has been deleted</p>
          <div class="hidden-events">
            <switches
              color="teal"
              theme="es"
              v-model="showHidden"
            ></switches>
            <span>
              {{ showHidden ? 'All Events' : 'Main Events' }}
            </span>
          </div>
        </div>
      </div>

      <div ref="chtFacesContainer">
        <div v-if="isBusy" class="d-flex justify-space-around">
          <v-progress-circular indeterminate />
        </div>
        <v-card class="my-4">
          <div v-for="sitting in chtEvents" :key="sitting.sittingid" class="pa-3">
            <div>Images for Sitting ID: <strong>{{ sitting.sittingid }}</strong></div>
            <div class="d-flex image-container pb-2">
              <div v-for="event in sitting.events" :key="event.event_timestamp" class="mr-2">
                <LazyProctoringImage
                  :userId="event.userID"
                  :sittingId="event.sittingid"
                  :imageName="event.filename"
                  className="cht-face-image"
                />
                <p><small>
                  {{ event.event_name }}<br />
                  <template v-if="event.seconds_into_test">
                    {{ event.seconds_into_test }} seconds into the test
                  </template>
                </small></p>
              </div>
            </div>
          </div>
        </v-card>
      </div>

      <table v-if="isReady" class="table-sticky">
        <thead>
          <tr>
            <th class="event-time">Event Time</th>
            <th class="event-data">Events</th>
          </tr>
        </thead>
      </table>
    </div>

    <div v-if="isReady" class="events-table" ref="eventsTable">
      <table class="table-data">
        <tbody>
          <tr v-if="isBusy">
            <td colspan="2">
              <div class="loading">
                <h4>Please wait ...</h4>
                <p>Fetching user events</p>
              </div>
            </td>
          </tr>
          <tr v-if="!isBusy && events.length === 0">
            <td colspan="2">
              <div class="no-events">
                <h4>No Events Found</h4>
              </div>
            </td>
          </tr>
          <template v-for="(event, $index) in getEvents">
            <tr
              :key="`event_${$index}`"
              class="event-row"
            >
              <td class="event-time">
                {{ event.event_timestamp | dateString }}
              </td>
              <td class="event-data">
                <div
                  class="event"
                  :class="{ 'active': activeEvents.includes($index) }"
                >
                  <div
                    class="event-name"
                    @click="() => openEventDetails($index)"
                  >
                    <fa :icon=" activeEvents.includes($index) ? 'minus': 'plus' " />
                    <strong>{{ event.event_name }} </strong>
                    <small>
                      {{decoration(event, 'page_view', 'page_title')}}
                      {{decoration(event, 'cht_mlkit_statechange', 'status')}}
                    </small>
                  </div>
                  <div class="event-details">
                    <template v-if="event.hasProctoringImage">
                      <LazyProctoringImage
                        :userId="event.userID"
                        :sittingId="event.sittingid"
                        :imageName="event.filename"
                        className="event-image"
                      />
                    </template>

                    <div class="event-params">
                      <template v-for="({ key, value }, $index) in event.event_params">
                        <div :key="`event_details_${$index}`">
                          <p class="events-params-details">
                            <span>{{ key }}</span> : <strong><span>{{ value.string_value || value.int_value || value.float_value || value.double_value }}</span></strong>
                          </p>
                        </div>
                      </template>
                    </div>
                    <div class="event-extra">
                      <div>
                        <p><strong>User Properties</strong></p>
                        <hr>
                        <div class="events-props">
                          <template v-for="({ key, value }, $index) in event.user_properties">
                            <p
                              :key="`event_props_${$index}`"
                              class="events-props-details"
                            >
                              <i><span>{{ key }}</span></i> : <strong><span>{{ value.string_value || value.int_value || value.float_value || value.double_value }}</span></strong>
                            </p>
                          </template>
                        </div>
                      </div>
                      <div>
                        <hr>
                        <p><strong>Device</strong></p>
                        <hr>
                        <div class="events-device">
                          <template v-for="(key, $index) in Object.keys(event.device)">
                            <p
                              :key="`event_device_${$index}`"
                              class="events-device-details"
                            >
                              <i><span>{{ key }}</span></i> : <strong><span>{{ event.device[key] }}</span></strong>
                            </p>
                          </template>
                        </div>
                      </div>
                      <div>
                        <hr>
                        <p><strong>Geo</strong></p>
                        <hr>
                        <div class="events-geo">
                          <template v-for="(key, $index) in Object.keys(event.geo)">
                            <p
                              :key="`event_geo_${$index}`"
                              class="events-geo-details"
                            >
                              <i><span>{{ key }}</span></i> : <strong><span>{{ event.geo[key] }}</span></strong>
                            </p>
                          </template>
                        </div>
                      </div>
                      <div v-if="event.app_info">
                        <hr>
                        <p><strong>App Info</strong></p>
                        <hr>
                        <div class="events-app-info">
                          <template v-for="(key, $index) in Object.keys(event.app_info)">
                            <p
                              :key="`event_app_info_${$index}`"
                              class="events-app-info-details"
                            >
                              <i><span>{{ key }}</span></i> : <strong><span>{{ event.app_info[key] }}</span></strong>
                            </p>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div
      v-if="isReady"
      class="bottom-sticky"
    >
      <div>
        <button
          class="es-btn teal"
          :disabled="!hasPreviousDate || isBusy"
          @click.prevent="previousDate"
        >
          <fa icon="chevron-left" />&nbsp;Previous Day
        </button>
      </div>
      <div>
        <button
          class="es-btn teal"
          :disabled="!hasNextDate || isBusy"
          @click.prevent="nextDate"
        >Next Day&nbsp;
          <fa icon="chevron-right" />
        </button>
      </div>
    </div>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import Switches from "vue-switches"
import ValidateUUID from "uuid-validate"
import { DateTime } from "luxon"
import LazyProctoringImage from '@/components/proctoring/LazyProctoringImage.vue'

export default {
  name: "UserEvents",
  components: {
    Switches,
    LazyProctoringImage
  },
  data: () => ({
    userID: "",
    eventDate: null,
    _debounce: null,
    activeEvents: [],
    isReady: false,
    showHidden: false
  }),
  computed: {
    ...mapGetters('userEvents', [
      'chtEvents',
      'events',
      'isBusy',
      'hiddenEvents',
      'userDeleted'
    ]),
    hasNextDate() {
      const date = new Date(this.eventDate)
      date.setDate(date.getDate() + 1)
      return date.getTime() < this.maxDate.getTime()
    },
    hasPreviousDate() {
      const date = new Date(this.eventDate)
      date.setDate(date.getDate() - 1)
      return date.getTime() > this.minDate.getTime()
    },
    getEvents() {
      if (this.showHidden) {
        return this.events
      }
      return this.events.filter(
        item => !this.hiddenEvents.includes(item.event_name)
      )
    },
    isDateValid() {
      if (!this.eventDate) return true
      return (
        this.minDate.getTime() < this.eventDate.getTime() &&
        this.maxDate.getTime() > this.eventDate.getTime()
      )
    },
    maxDate() {
      const date = new Date()
      date.setDate(date.getDate())
      return date
    },
    minDate() {
      const date = new Date(2018, 0, 1)
      return date
    },
    isUserIDValid() {
      if (!this.userID) return true
      return ValidateUUID(this.userID, 4)
    }
  },
  methods: {
    ...mapActions({
      loadEvents: "userEvents/loadEvents"
    }),
    decoration(event, name, pluck) {
      if (event.event_name.toLowerCase() !== name) return ""

      const match = event.event_params.find(({ key }) => key === pluck)
      if (!match) return ""

      return match.value.string_value
    },
    datepickerDisabledDate: function(date) {
      return date > new Date(new Date().setHours(23,59,59))
    },
    nextDate() {
      if (this.hasNextDate) {
        const date = new Date(this.eventDate)
        date.setDate(date.getDate() + 1)
        this.eventDate = date

        const ed = DateTime.fromJSDate(this.eventDate).toFormat("yyyy,MM,dd")
        this.$router.push({ name: "UserEvents", params: this.$route.params, query: {
          ...this.$route.query,
          ed
        }})
      }
    },
    previousDate() {
      if (this.hasPreviousDate) {
        const date = new Date(this.eventDate)
        date.setDate(date.getDate() - 1)
        this.eventDate = date

        const ed = DateTime.fromJSDate(this.eventDate).toFormat("yyyy,MM,dd")
        this.$router.push({ name: "UserEvents", params: this.$route.params, query: {
          ...this.$route.query,
          ed
        }})
      }
    },
    submitForm() {
      const query = {
        ...this.$route.query,
        uid: this.userID,
        ed: DateTime.fromJSDate(this.eventDate).toFormat("yyyy,MM,dd"),
      }
      // Pushing the route will trigger the watcher below, which calls fetchEvents().
      this.$router.push({ name: "UserEvents", params: this.$route.params, query })
    },
    fetchEvents() {
      this.isReady = true
      this.activeEvents = []
      clearTimeout(this._debounce)
      this._debounce = setTimeout(() => {
        this.loadEvents({
          userID: this.userID,
          eventDate: DateTime.fromJSDate(this.eventDate).toFormat("yyyyMMdd")
        })
      }, 100)
    },
    openEventDetails(index) {
      this.events[index].active = true
      if (!this.activeEvents.includes(index)) {
        this.activeEvents = [...this.activeEvents, index]
      } else {
        const tempIndex = this.activeEvents.indexOf(index)
        this.activeEvents = [
          ...this.activeEvents.slice(0, tempIndex),
          ...this.activeEvents.slice(tempIndex + 1)
        ]
      }
    },
  },
  watch: {
    events() {
      const imageEvents = this.getEvents.reduce((accumulator, { hasProctoringImage }, index) => {
        if (hasProctoringImage) accumulator.push(index)
        return accumulator
      }, [])
      this.activeEvents = [...this.activeEvents, ...imageEvents]
    },
    showHidden(showing) {
      if (this.$route.query.sh === showing) return

      const query = { ...this.$route.query }
      if (!showing) delete query.sh
      else query.sh = true
      this.$router.push({ name: "UserEvents", params: this.$route.params, query })
    },
    $route: {
      handler() {
        const { uid: userID, ed: eventDate, sh: showHidden } = this.$route.query

        this.userID = userID || ""
        if (eventDate) { this.eventDate = new Date(eventDate) }
        this.showHidden = !!showHidden

        // The userId and eventDate might have been set by the query params. If so, fetch the events.
        if (this.userID && this.eventDate) {
          this.fetchEvents()
        }
      },
      immediate: true
    },
  },
  mounted() {
    // Hide the "faces container" when the user scrolls the table.
    window.onscroll = () => {
      const { top: eventsTableTop } = this.$refs.eventsTable.getBoundingClientRect()
      if (this.$refs.chtFacesContainer.style.display !== "none") {
        const { top: chtFacesContainerTop } = this.$refs.chtFacesContainer.getBoundingClientRect()
        window.chtFacesContainerTop = chtFacesContainerTop
      }
      const overlapping = eventsTableTop < window.chtFacesContainerTop
      this.$refs.chtFacesContainer.style.display = overlapping ? 'none' : 'block'
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .mx-input {
    padding: 17px 30px 20px 10px !important;
  }
}
.user-events {
  padding-bottom: 60px;
  & .top-sticky {
    top: 133px;
    background: white;
    position: sticky;
    left: 0;
    z-index: 1;
    .event-form {
      background: white;
      padding: 0 1em;
      display: flex;
      flex-direction: revert;
      justify-content: space-between;
      align-items: flex-end;
      & form {
        display: flex;
        flex-direction: row;
        & .form-row {
          display: flex;
          flex-direction: column;
          margin-right: 1em;
          & label {
            display: block;
            font-size: 12px;
            cursor: pointer;
            & svg {
              color: $cranberry;
              width: 8px;
              vertical-align: top;
              margin-left: 0.25em;
            }
          }
          &.error {
            & input {
              border-color: $cranberry;
            }
          }
          & input {
            width: 100%;
            min-width: 300px;
            margin-top: 0.25em;
            padding: 0.5em;
            outline: none;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 14px;
            &:focus {
              border-color: $dark-indigo;
            }
          }
          & p.error {
            font-size: 14px;
            color: $cranberry;
            margin-top: 0.25em;
            margin-bottom: 0;
          }
        }
        & button {
          margin-top: 25px;
          height: 36px;
          align-self: top;
        }
      }
    }

    & .table-sticky {
      margin-top: 1.5em;
      width: 100%;
      border-collapse: collapse;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      overflow: hidden;
      & th {
        vertical-align: top;
        background-color: $dark-indigo;
        color: white;
        text-align: left;
        padding: 0.5em 1em;
      }
    }
  }
  .events-table {
    & .table-data {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #ccc;
      border-top: none;
      margin-bottom: 2em;
      & td {
        vertical-align: top;
        padding: 0.5em 1em;
        &.event-time {
          white-space: nowrap;
          min-width: 280px;
        }
        & .no-events,
        & .loading {
          & h4 {
            color: $dark-indigo;
            text-align: center;
            margin: 0.5em;
          }
          & p {
            text-align: center;
            font-size: 14px;
            color: rgba(black, 0.87);
          }
        }
      }
    }

    & .event {
      & .event-name {
        cursor: pointer;
        user-select: none;
        margin-bottom: 10px;
        & svg {
          margin-right: 0.5em;
          width: 10px;
          color: #ccc;
        }
      }
      & .event-details {
        display: flex;
        opacity: 0;
        height: 0;
        transition: 0.2s;
        will-change: auto;
        flex-direction: row;
        justify-content: space-between;
        align-items: top;
        overflow: hidden;

        & .event-image {
          position: absolute;
          left: 15px;
          width: 260px;
          height: 340px;
        }
      }
      &.active {
        & .event-details {
          opacity: 1;
          height: auto;
        }
      }
    }
  }

  .bottom-sticky {
    padding: 0 3em;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }
}
.image-container {
  max-width: 100%;
  overflow-x: auto;
}
.cht-face-image {
  width: 125px;
}
p small {
  font-size: x-small;
}

.text-danger {
  color: red;
  font-weight: bold;
}

</style>
