<template>
  <div class="lazy-image" :class="className" ref="images"></div>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import NoImage from './../../assets/images/no-image.png'

export default {
  name: 'LazyProctoringImage',
  props: ['userId', 'sittingId', 'imageName', 'className'],
  async mounted() {
    try {
      const storage = firebase.app().storage(process.env.VUE_APP_PROCTORING_BUCKET)
      const imageDirRef = storage.ref(`${this.userId}/${this.sittingId}`)
    
      const img = document.createElement('img')
      const imageRef = imageDirRef.child(this.imageName)

      try {
          const imageUrl = await imageRef.getDownloadURL()
          img.src = imageUrl
      } catch(err) {
          img.src = NoImage
      }
        
      this.$refs.images.appendChild(img)

    } catch(err) {
      // console.log(err)
    }
  },
  
}
</script>
<style lang="scss">
.lazy-image {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 150px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
